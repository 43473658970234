<template>
  <div class="bx--grid bx--grid--full-width">
    <div class="bx--row">
      <div class="bx--col bx--no-gutter">
        <div class="bx--col-lg-10 bx--offset-lg-4 simple__page">
          <h1>Code ! </h1>
          <br/>
          We will soon be adding sample code for different langauges & framework so that you don't need to go to stackoverflow.
        </div>
      </div>
    </div>
  </div>
</template>

<script>



export default {
  name: 'Why',
  components: {},
  created () {

  },
  data: function () {
    return {}
  },
  methods: {

  }
};
</script>

<style lang="scss">
.simple__page{

  padding-top: 5em;
  padding-bottom: 5em;
  min-height: 90vh;
}

ul{
list-style: disc;

  li{
    padding-top: 0.5em;
  }
}
</style>
